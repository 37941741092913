import React from "react";
import { easings, useTransition } from "react-spring";
import { defineMessage, useIntl } from "react-intl";
import {
  QuestionContainer,
  QuestionSlider,
  QuestionBlock,
  QuestionTitle,
  QuestionText,
} from "./index.styled";

import QuestionsData from "./data";

interface Props {
  activeQuestionId: string;
}

export const Questions: React.FC<Props> = ({ activeQuestionId }) => {
  const [show] = React.useState(true);
  const intl = useIntl();
  const transitions = useTransition(show, {
    from: { opacity: 0, y: 100 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: -100 },
    reset: true,
    config: {
      duration: 500,
      easings: (t: number) => easings.easeInOutCubic(t),
    },
  });

  const activeQuestion =
    QuestionsData.find((question) => question.id === activeQuestionId) ||
    QuestionsData[0];

  return transitions((styles) => (
    <QuestionContainer className="show">
      <QuestionSlider style={{ ...styles }} className="show">
        <QuestionBlock className="show">
          {activeQuestion.index && (
            <QuestionTitle>
              {intl.formatMessage(defineMessage({ id: "question.title" }), {
                index: activeQuestion.index,
              })}
            </QuestionTitle>
          )}
          <QuestionText>
            {intl.formatMessage(activeQuestion.message)}
          </QuestionText>
        </QuestionBlock>
      </QuestionSlider>
    </QuestionContainer>
  ));
};
