import { DefaultTheme, ThemeProps } from "styled-components";

export interface npsLandingTheme extends DefaultTheme {
  color?: Record<string, string>;
  transition03?: string;
  transition05?: string;
  visible?: string;
  invisible?: string;
}

export type npsLandingThemeProps = ThemeProps<npsLandingTheme>;

const theme: npsLandingTheme = {
  color: {
    black: "#000",
    megafon_green: "#13b053",
  },
  transition03: "transition: .3s ease-in-out;",
  transition05: "transition: .5s ease-in-out;",
  visible: `
    visibility: visible; 
    opacity: 1; 
    pointer-events: visible;`,

  invisible: `
    visibility: hidden; 
    opacity: 0; 
    pointer-events: none;`,
};

export default theme;
