import React from "react";
import { IntlProvider } from "react-intl";
import LocaleContext, { AppLocale, LocaleContextData } from "./index";
import localeEn from "../../i18n/en.json";
import localeRu from "../../i18n/ru.json";

// eslint-disable-next-line react/function-component-definition
const LocaleProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  // do not set default locale here, it will break the CI environment
  const DEFAULT_LOCALE = process.env.REACT_APP_LOCALE as AppLocale;

  const [locale, changeLocale] = React.useState<AppLocale>(DEFAULT_LOCALE);
  const contextValue = React.useMemo<LocaleContextData>(
    () => ({
      locale: locale === "en" ? "en" : "ru",
      changeLocale,
    }),
    [locale]
  );

  const currentLocaleMessages = locale === "en" ? localeEn : localeRu;

  return (
    <LocaleContext.Provider value={contextValue}>
      <IntlProvider
        locale={locale}
        defaultLocale={DEFAULT_LOCALE === "en" ? "en" : "ru"}
        messages={currentLocaleMessages}
      >
        {children}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
