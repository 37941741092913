import { createGlobalStyle } from "styled-components";
import { npsLandingTheme } from "./themes";

import "./vendors/_reset.css";

import "./fonts/Inter/stylesheet.css";

const GlobalStyles = createGlobalStyle<npsLandingTheme>`
  * { 
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
  }

  body {
    position: fixed;

    top: 0;
    left: 0;
    overflow: hidden;
    font-family: Inter, sans-serif;
    font-style: normal;
    transition: max-width .3s linear, max-height .3s linear;
    user-select: none;

    &.hidden { overflow: hidden; }

    > * {
      max-width: inherit;
      max-height: inherit;
      
      overflow: auto;
    }
  }

  *:after, 
  *:before {
    box-sizing: border-box;
  }
`;

export default GlobalStyles;
