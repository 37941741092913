import { animated } from "react-spring";
import styled from "styled-components";
import { media } from "../../styles/media";

const coef = 0.75;

export const QuestionContainer = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  transition: 0.3s linear;

  ${media.mobilePortrait} {
    width: calc(100vw / 390 * 326);
    max-width: calc(100vw / 390 * 326);

    margin-top: calc(100vw / 390 * 48);
  }
  ${media.mobileLandscape} {
    width: calc(100vh / 390 * (326 * ${coef}));
    max-width: calc(100vh / 390 * (326 * ${coef}));

    margin-top: calc(100vh / 390 * (48 * ${coef}));
  }
  ${media.tablet} {
    width: calc(326px * (1 / 390 * 550));
    max-width: calc(326px * (1 / 390 * 550));

    margin-top: calc(48px * (1 / 390 * 550));
  }

  ${(props) => props.theme.invisible};

  &.show {
    ${(props) => props.theme.visible}
  }
`;
export const QuestionSlider = styled(animated.div)`
  position: relative;
  display: block;

  width: 100%;
  transition: bottom 0.75s linear;
`;
export const QuestionBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s linear;

  ${(props) => props.theme.invisible};

  &.show {
    ${(props) => props.theme.visible}
  }
`;
export const QuestionTitle = styled.p`
  position: relative;
  display: inline-block;

  width: 100%;
  color: ${(props) => props.theme.color.megafon_green};
  font-weight: 600;
  font-family: Inter, sans-serif;
  font-style: normal;
  line-height: 1.5;
  text-align: center;
  font-size: calc(16px * (1 / 390 * 550));

  ${media.mobilePortrait} {
    font-size: calc(100vw / 390 * 16);
  }
  ${media.mobileLandscape} {
    font-size: calc(100vh / 390 * (16 * ${coef}));
  }
  ${media.tablet} {
    font-size: calc(16px * (1 / 390 * 550));
  }
`;
export const QuestionText = styled.p`
  position: relative;
  display: inline-block;

  width: 100%;
  color: #000;
  font-weight: 700;
  font-family: Inter, sans-serif;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: -0.24px;
  text-align: center;

  ${media.mobilePortrait} {
    margin-top: calc(100vw / 390 * 8);
    font-size: calc(100vw / 390 * 20);
  }
  ${media.mobileLandscape} {
    margin-top: calc(100vh / 390 * (8 * ${coef}));
    font-size: calc(100vh / 390 * (20 * ${coef}));
  }
  ${media.tablet} {
    margin-top: calc(8px * (1 / 390 * 550));
    font-size: calc(20px * (1 / 390 * 550));
  }
`;
