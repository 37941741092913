import React, { useContext } from "react";
import { get } from "lodash";
import { LandingBlock, LogoWrap } from "./index.styled";
import LogoEN from "../../components/GiFs/logo01.gif";
import LogoRU from "../../components/GiFs/full-logo.svg";

import { Questions } from "../Questions";
import LocaleContext from "../../contexts/locale";

// eslint-disable-next-line
export const Main: React.FC = () =>
{
  const { locale } = useContext(LocaleContext);
  const [activeScreen, setActiveScreen] = React.useState("start");
  const changeScreenCallback = (event: unknown) => {
    const screenName = get(event, "payload.args", "hello");

    // eslint-disable-next-line no-console
    console.log("CHANGE_SCREEN", screenName);
    setActiveScreen(screenName);
  };

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    KvintNavigator.on("script:change_screen", changeScreenCallback);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      KvintNavigator.off("script:change_screen", changeScreenCallback);
    };
  }, []);

  return (
    <LandingBlock>
      <LogoWrap src={`${locale === "en" ? LogoEN : LogoRU}`} />
      <Questions activeQuestionId={activeScreen} />
    </LandingBlock>
  );
};
