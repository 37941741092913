import React from "react";
import { ThemeProvider } from "styled-components";
import theme from "./styles/themes";
import GlobalStyles from "./styles/global";
import { Main } from "./containers/Main";
import LocaleProvider from "./contexts/locale/provider";
import WidgetContextProvider from "./contexts/widget/provider";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <LocaleProvider>
        <WidgetContextProvider>
          <Main />
        </WidgetContextProvider>
      </LocaleProvider>
    </ThemeProvider>
  );
}

export default App;
