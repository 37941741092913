import { defineMessage, MessageDescriptor } from "react-intl";

export type QuestionsType = {
  id: string;
  index?: number;
  message: MessageDescriptor;
};

const QuestionsData: QuestionsType[] = [
  {
    id: "start",
    message: defineMessage({ id: "questions.start" }),
  },
  {
    id: "satisfaction_question",
    index: 1,
    message: defineMessage({ id: "questions.satisfaction_question" }),
  },
  {
    id: "low_satisfaction",
    message: defineMessage({ id: "questions.low_satisfaction" }),
  },
  {
    id: "loyalty_question",
    index: 2,
    message: defineMessage({ id: "questions.loyalty_question" }),
  },
  {
    id: "low_loyalty",
    message: defineMessage({ id: "questions.low_loyalty" }),
  },
  {
    id: "goodbye",
    message: defineMessage({ id: "questions.goodbye" }),
  },
];

export default QuestionsData;
