import React, { useContext, useEffect } from "react";
import LocaleContext from "../locale";
import IconEN from "../../components/GiFs/favicon_en.ico";
import IconRU from "../../components/GiFs/favicon_ru.ico";

// eslint-disable-next-line react/function-component-definition
const WidgetContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { locale } = useContext(LocaleContext);
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    KvintNavigator.init({
      token: process.env.REACT_APP_KVINT_TOKEN,
      campaignId: process.env.REACT_APP_KVINT_CAMPAIGN_ID,
      language: locale,
    });

    const favicon = document.getElementById("favicon") as HTMLLinkElement;
    favicon.href = locale === "en" ? IconEN : IconRU;

    const title = document.getElementById("tab_title") as HTMLTitleElement;
    title.innerHTML = `${
      locale === "en" ? "JIQ" : "KVINT"
    } Mobile Assistant Demo`;
  }, []);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default WidgetContextProvider;
