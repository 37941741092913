import styled from "styled-components";
import { media } from "../../styles/media";

const coef = 0.75;

export const LandingBlock = styled.div`
  position: fixed;

  display: flex;
  flex-direction: column;
  align-items: center;

  left: 50%;
  margin-right: -50%;

  transform: translateX(-50%);
  top: 10vw;

  ${media.mobilePortrait} {
    top: calc(100vw / 390 * 80);
  }
  ${media.mobileLandscape} {
    top: 7.5vh;
  }
  ${media.tablet} {
    top: 10vh;
  }
`;

export const LogoWrap = styled.img.attrs({
  alt: "logo",
})`
  position: relative;
  width: calc(128px * (1 / 390 * 750));
  min-width: calc(128px * (1 / 390 * 750));

  ${media.mobilePortrait} {
    width: calc(100vw / 390 * 128);
    min-width: calc(100vw / 390 * 128);
  }
  ${media.mobileLandscape} {
    width: calc(100vh / 390 * (128 * ${coef}));
    min-width: calc(100vh / 390 * (128 * ${coef}));
  }
  ${media.tablet} {
    width: calc(128px * (1 / 390 * 550));
    min-width: calc(128px * (1 / 390 * 550));
  }
`;

export const BtnPrev = styled.button`
  position: fixed;

  bottom: 0;
  left: 0;
  padding: 16px;
  font-size: 16px;
  background: #ccc;
`;
export const BtnNext = styled.button`
  position: fixed;

  right: 0;
  top: 0;
  padding: 16px;
  font-size: 16px;
  background: #ccc;
`;
