import { createContext } from "react";

export type AppLocale = "ru" | "en";

export interface LocaleContextData {
  locale: AppLocale;
  changeLocale: (locale: AppLocale) => void;
}

const LocaleContext = createContext<LocaleContextData>({
  locale: "ru",
  changeLocale: () => {
    throw new Error("changeLocale function is not defined");
  },
});

export default LocaleContext;
